import { styled } from 'styled-components'
import { onMediumScreen } from 'mixtiles-web-common'
import { XIcon } from '../../../icons'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.grayBackgroundWhite};
  height: 100%;

  ${onMediumScreen()} {
    width: 415px;
  }
`

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayMedium};
  ${onMediumScreen()} {
    padding: 20px;
  }
`

export const Label = styled.div`
  font-size: 15px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 6px;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.black};
`

export const CloseButton = styled(XIcon)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`

export const DoneButton = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`
