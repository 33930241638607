import { cc } from '../locale/custom/countries'
import omit from 'lodash/omit'
import { isClient } from 'utils/runtimeUtils'

const uiLang = isClient()
  ? window.__remixContext?.state?.loaderData?.root?.language || 'en'
  : 'en'

export const REGIONS = {
  UNITED_STATES: 'United States',
  WESTERN_EUROPE: 'Western Europe',
  EASTERN_EUROPE: 'Eastern Europe',
  CANADA: 'Canada',
  AUSTRALIA: 'Australia',
  SOUTH_AMERICA: 'South America',
  CENTRAL_AMERICA: 'Central America',
  ISRAEL: 'Israel',
  GLOBAL: 'Global',
  ASIA: 'Asia',
}

/* This sets the fields display order.
   Only fields relevant to the selected country will be shown, followed by Country select field. */
export const ADDRESS_INPUT_FIELDS = [
  'fullName',
  'street',
  'address_2',
  'city',
  'state',
  'zipCode',
  'phoneNumber',
]

const COUNTRY_INPUTS = {
  UNITED_STATES: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.state',
    zipCode: 'general.address_form.form_fields.zip',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  CANADA: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.province',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  GERMANY: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  UNITED_KINGDOM: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'Postcode',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  ISRAEL: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.zip',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  AUSTRIA: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  NETHERLANDS: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'Postcode',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  SWEDEN: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'Postnummer',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  DENMARK: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'Postnumre',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  AUSTRALIA: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.state_or_territory',
    zipCode: 'Postcode',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  SPAIN: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  FRANCE: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  BELGIUM: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  ITALY: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  IRELAND: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.country',
    zipCode: 'Eircode',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  POLAND: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  JAPAN: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.state_or_province',
    zipCode: '郵便番号',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  FINLAND: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
  GLOBAL: {
    fullName: 'general.address_form.form_fields.full_name',
    street: 'general.address_form.form_fields.address',
    address_2: 'general.address_form.form_fields.address_2',
    city: 'general.address_form.form_fields.city',
    state: 'general.address_form.form_fields.state_or_province',
    zipCode: 'general.address_form.form_fields.postal',
    phoneNumber: 'general.address_form.form_fields.phone',
  },
}

interface Country {
  id: string
  name: string
  code: string
  region: string
  inputs: Record<string, string>
}

export const COUNTRIES: Record<string, Country> = {
  UNITED_STATES: {
    id: 'UNITED_STATES',
    name: cc[uiLang as keyof typeof cc].US,
    code: 'US',
    region: REGIONS.UNITED_STATES,
    inputs: COUNTRY_INPUTS.UNITED_STATES,
  },
  CANADA: {
    id: 'CANADA',
    name: cc[uiLang as keyof typeof cc].CA,
    code: 'CA',
    region: REGIONS.CANADA,
    inputs: COUNTRY_INPUTS.CANADA,
  },
  GERMANY: {
    id: 'GERMANY',
    name: cc[uiLang as keyof typeof cc].DE,
    code: 'DE',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.GERMANY,
  },
  UNITED_KINGDOM: {
    id: 'UNITED_KINGDOM',
    name: cc[uiLang as keyof typeof cc].GB,
    code: 'GB',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.UNITED_KINGDOM,
  },
  ISRAEL: {
    id: 'ISRAEL',
    name: cc[uiLang as keyof typeof cc].IL,
    code: 'IL',
    region: REGIONS.ISRAEL,
    inputs: COUNTRY_INPUTS.ISRAEL,
  },
  AUSTRIA: {
    id: 'AUSTRIA',
    name: cc[uiLang as keyof typeof cc].AT,
    code: 'AT',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.AUSTRIA,
  },
  NETHERLANDS: {
    id: 'NETHERLANDS',
    name: cc[uiLang as keyof typeof cc].NL,
    code: 'NL',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.NETHERLANDS,
  },
  SWEDEN: {
    id: 'SWEDEN',
    name: cc[uiLang as keyof typeof cc].SE,
    code: 'SE',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.SWEDEN,
  },
  DENMARK: {
    id: 'DENMARK',
    name: cc[uiLang as keyof typeof cc].DK,
    code: 'DK',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.DENMARK,
  },
  AUSTRALIA: {
    id: 'AUSTRALIA',
    name: cc[uiLang as keyof typeof cc].AU,
    code: 'AU',
    region: REGIONS.AUSTRALIA,
    inputs: COUNTRY_INPUTS.AUSTRALIA,
  },
  SPAIN: {
    id: 'SPAIN',
    name: cc[uiLang as keyof typeof cc].ES,
    code: 'ES',
    region: REGIONS.CENTRAL_AMERICA,
    inputs: COUNTRY_INPUTS.SPAIN,
  },
  FRANCE: {
    id: 'FRANCE',
    name: cc[uiLang as keyof typeof cc].FR,
    code: 'FR',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.FRANCE,
  },
  BELGIUM: {
    id: 'BELGIUM',
    name: cc[uiLang as keyof typeof cc].BE,
    code: 'BE',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.BELGIUM,
  },
  ITALY: {
    id: 'ITALY',
    name: cc[uiLang as keyof typeof cc].IT,
    code: 'IT',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.ITALY,
  },
  IRELAND: {
    id: 'IRELAND',
    name: cc[uiLang as keyof typeof cc].IE,
    code: 'IE',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.IRELAND,
  },
  POLAND: {
    id: 'POLAND',
    name: cc[uiLang as keyof typeof cc].PL,
    code: 'PL',
    region: REGIONS.EASTERN_EUROPE,
    inputs: COUNTRY_INPUTS.POLAND,
  },
  JAPAN: {
    id: 'JAPAN',
    name: cc[uiLang as keyof typeof cc].JP,
    code: 'JP',
    region: REGIONS.ASIA,
    inputs: COUNTRY_INPUTS.JAPAN,
  },
  FINLAND: {
    id: 'FINLAND',
    name: cc[uiLang as keyof typeof cc].FI,
    code: 'FI',
    region: REGIONS.WESTERN_EUROPE,
    inputs: COUNTRY_INPUTS.FINLAND,
  },
}

export const GLOBAL_COUNTRIES: Record<string, any> = {
  // AF: 'Afghanistan',
  AL: cc[uiLang as keyof typeof cc].AL,
  // DZ: 'Algeria',
  // AD: cc[uiLang as keyof typeof cc]['AD'], Andorra
  AO: cc[uiLang as keyof typeof cc].AO,
  AI: cc[uiLang as keyof typeof cc].AI,
  AG: cc[uiLang as keyof typeof cc].AG,
  AR: cc[uiLang as keyof typeof cc].AR,
  // AM: 'Armenia',
  AW: cc[uiLang as keyof typeof cc].AW,
  AU: cc[uiLang as keyof typeof cc].AU,
  // AZ: 'Azerbaijan',
  BO: cc[uiLang as keyof typeof cc].BO,
  BS: cc[uiLang as keyof typeof cc].BS,
  BH: cc[uiLang as keyof typeof cc].BH,
  BD: cc[uiLang as keyof typeof cc].BD,
  BB: cc[uiLang as keyof typeof cc].BB,
  // BY: 'Belarus',
  BZ: cc[uiLang as keyof typeof cc].BZ,
  BJ: cc[uiLang as keyof typeof cc].BJ,
  BM: cc[uiLang as keyof typeof cc].BM,
  BT: cc[uiLang as keyof typeof cc].BT,
  BQ: cc[uiLang as keyof typeof cc].BQ,
  BA: cc[uiLang as keyof typeof cc].BA,
  BW: cc[uiLang as keyof typeof cc].BW,
  // BR: cc[uiLang as keyof typeof cc].BR, Brazil
  VG: cc[uiLang as keyof typeof cc].VG,
  BN: cc[uiLang as keyof typeof cc].BN,
  BG: cc[uiLang as keyof typeof cc].BG,
  BF: cc[uiLang as keyof typeof cc].BF,
  // MM: cc[uiLang as keyof typeof cc]['MM'], Myanmar
  BI: cc[uiLang as keyof typeof cc].BI,
  // KH: cc[uiLang as keyof typeof cc]['KH'],
  // CM: 'Cameroon',
  // CV: 'Cape Verde',
  KY: cc[uiLang as keyof typeof cc].KY,
  // CF: 'Central African Republic',
  TD: cc[uiLang as keyof typeof cc].TD,
  CL: cc[uiLang as keyof typeof cc].CL,
  // CN: 'China',
  CO: cc[uiLang as keyof typeof cc].CO,
  // KM: 'Comoros',
  // CD: 'Congo, Democratic Republic of the',
  CG: cc[uiLang as keyof typeof cc].CG,
  CR: cc[uiLang as keyof typeof cc].CR,
  // CI: 'Cote d’Ivoire (Ivory Coast)',
  HR: cc[uiLang as keyof typeof cc].HR,
  // CU: 'Cuba',
  CW: cc[uiLang as keyof typeof cc].CW,
  CY: cc[uiLang as keyof typeof cc].CY,
  CZ: cc[uiLang as keyof typeof cc].CZ,
  // DJ: 'Djibouti',
  DM: cc[uiLang as keyof typeof cc].DM,
  DO: cc[uiLang as keyof typeof cc].DO,
  EC: cc[uiLang as keyof typeof cc].EC,
  EG: cc[uiLang as keyof typeof cc].EG,
  SV: cc[uiLang as keyof typeof cc].SV,
  GQ: cc[uiLang as keyof typeof cc].GQ,
  ER: cc[uiLang as keyof typeof cc].ER,
  EE: cc[uiLang as keyof typeof cc].EE,
  SZ: cc[uiLang as keyof typeof cc].SZ,
  ET: cc[uiLang as keyof typeof cc].ET,
  // FO: 'Faroe Islands',
  // FJ: cc[uiLang as keyof typeof cc]['FJ'],
  GF: cc[uiLang as keyof typeof cc].GF,
  PF: cc[uiLang as keyof typeof cc].PF,
  GA: cc[uiLang as keyof typeof cc].GA,
  GM: cc[uiLang as keyof typeof cc].GM,
  // GE: 'Georgia',
  // GH: cc[uiLang as keyof typeof cc]['GH'],
  GI: cc[uiLang as keyof typeof cc].GI,
  GR: cc[uiLang as keyof typeof cc].GR,
  // GL: 'Greenland',
  GD: cc[uiLang as keyof typeof cc].GD,
  GP: cc[uiLang as keyof typeof cc].GP,
  GT: cc[uiLang as keyof typeof cc].GT,
  // GN: 'Guinea',
  // GW: 'Guinea-Bissau',
  GY: cc[uiLang as keyof typeof cc].GY,
  HT: cc[uiLang as keyof typeof cc].HT,
  HN: cc[uiLang as keyof typeof cc].HN,
  HK: cc[uiLang as keyof typeof cc].HK,
  HU: cc[uiLang as keyof typeof cc].HU,
  IS: cc[uiLang as keyof typeof cc].IS,
  // IN: cc[uiLang as keyof typeof cc]['IN'],
  ID: cc[uiLang as keyof typeof cc].ID,
  JM: cc[uiLang as keyof typeof cc].JM,
  // JP: 'Japan',
  JO: cc[uiLang as keyof typeof cc].JO,
  // KZ: 'Kazakhstan',
  KE: cc[uiLang as keyof typeof cc].KE,
  // KI: 'Kiribati',
  KR: cc[uiLang as keyof typeof cc].KR,
  // XK: 'Kosovo',
  // KW: cc[uiLang as keyof typeof cc]['KW'], // Kuwait has shipping issues
  // KG: 'Kyrgyzstan',
  // LA: cc[uiLang as keyof typeof cc]['LA'], // Lao People's Democratic Republic
  LV: cc[uiLang as keyof typeof cc].LV,
  LS: cc[uiLang as keyof typeof cc].LS,
  // LR: 'Liberia',
  // LY: 'Libya',
  LI: cc[uiLang as keyof typeof cc].LI,
  LT: cc[uiLang as keyof typeof cc].LT,
  LU: cc[uiLang as keyof typeof cc].LU,
  MC: cc[uiLang as keyof typeof cc].MC,
  MO: cc[uiLang as keyof typeof cc].MO,
  // MK: 'Macedonia (Republic of)',
  MG: cc[uiLang as keyof typeof cc].MG,
  MW: cc[uiLang as keyof typeof cc].MW,
  MY: cc[uiLang as keyof typeof cc].MY,
  MV: cc[uiLang as keyof typeof cc].MV,
  ML: cc[uiLang as keyof typeof cc].ML,
  MT: cc[uiLang as keyof typeof cc].MT,
  MQ: cc[uiLang as keyof typeof cc].MQ,
  // MR: 'Mauritania',
  MU: cc[uiLang as keyof typeof cc].MU,
  MX: cc[uiLang as keyof typeof cc].MX,
  // MD: 'Moldova',
  // MN: cc[uiLang as keyof typeof cc]['MN'],
  ME: cc[uiLang as keyof typeof cc].ME,
  MS: cc[uiLang as keyof typeof cc].MS,
  MA: cc[uiLang as keyof typeof cc].MA,
  MZ: cc[uiLang as keyof typeof cc].MZ,
  NA: cc[uiLang as keyof typeof cc].NA,
  // NR: 'Nauru',
  // NP: 'Nepal',
  // NC: cc[uiLang as keyof typeof cc]['NC'],  New Caledonia
  NZ: cc[uiLang as keyof typeof cc].NZ,
  NI: cc[uiLang as keyof typeof cc].NI,
  NE: cc[uiLang as keyof typeof cc].NE,
  NG: cc[uiLang as keyof typeof cc].NG,
  NO: cc[uiLang as keyof typeof cc].NO,
  OM: cc[uiLang as keyof typeof cc].OM,
  PK: cc[uiLang as keyof typeof cc].PK,
  PA: cc[uiLang as keyof typeof cc].PA,
  // PG: cc[uiLang as keyof typeof cc]['PG'], Papua New Guinea
  PY: cc[uiLang as keyof typeof cc].PY,
  PE: cc[uiLang as keyof typeof cc].PE,
  PH: cc[uiLang as keyof typeof cc].PH,
  PN: cc[uiLang as keyof typeof cc].PN,
  PR: cc[uiLang as keyof typeof cc].PR,
  PT: cc[uiLang as keyof typeof cc].PT,
  QA: cc[uiLang as keyof typeof cc].QA,
  RE: cc[uiLang as keyof typeof cc].RE,
  RO: cc[uiLang as keyof typeof cc].RO,
  // RU: 'Russia',
  RW: cc[uiLang as keyof typeof cc].RW,
  // SH: 'Saint Helena',
  KN: cc[uiLang as keyof typeof cc].KN,
  LC: cc[uiLang as keyof typeof cc].LC,
  PM: cc[uiLang as keyof typeof cc].PM,
  VC: cc[uiLang as keyof typeof cc].VC,
  // WS: 'Samoa',
  SM: cc[uiLang as keyof typeof cc].SM,
  // ST: 'Sao Tome and Principe',
  SA: cc[uiLang as keyof typeof cc].SA,
  SN: cc[uiLang as keyof typeof cc].SN,
  RS: cc[uiLang as keyof typeof cc].RS,
  // SC: cc[uiLang as keyof typeof cc]['SC'],
  // SL: 'Sierra Leone',
  SG: cc[uiLang as keyof typeof cc].SG,
  SX: cc[uiLang as keyof typeof cc].SX,
  SK: cc[uiLang as keyof typeof cc].SK,
  SI: cc[uiLang as keyof typeof cc].SI,
  // SB: 'Solomon Islands',
  ZA: cc[uiLang as keyof typeof cc].ZA,
  LK: cc[uiLang as keyof typeof cc].LK,
  // SD: 'Sudan',
  // SR: 'Suriname',
  CH: cc[uiLang as keyof typeof cc].CH,
  TW: cc[uiLang as keyof typeof cc].TW,
  // TJ: 'Tajikistan',
  TZ: cc[uiLang as keyof typeof cc].TZ,
  TH: cc[uiLang as keyof typeof cc].TH,
  TL: cc[uiLang as keyof typeof cc].TL,
  // TG: cc[uiLang as keyof typeof cc]['TG'],
  // TO: 'Tonga',
  TT: cc[uiLang as keyof typeof cc].TT,
  // TN: 'Tunisia',
  // TR: cc[uiLang as keyof typeof cc]['TR'],
  // TM: 'Turkmenistan',
  TC: cc[uiLang as keyof typeof cc].TC,
  // TV: 'Tuvalu',
  UG: cc[uiLang as keyof typeof cc].UG,
  // UA: 'Ukraine',
  AE: cc[uiLang as keyof typeof cc].AE,
  UY: cc[uiLang as keyof typeof cc].UY,
  // UZ: 'Uzbekistan',
  // VU: cc[uiLang as keyof typeof cc]['VU'],
  VA: cc[uiLang as keyof typeof cc].VA,
  VE: cc[uiLang as keyof typeof cc].VE,
  // VN: cc[uiLang as keyof typeof cc]['VN'],
  // WF: cc[uiLang as keyof typeof cc]['WF'],
  // YE: 'Yemen',
  ZM: cc[uiLang as keyof typeof cc].ZM,
  // ZW: 'Zimbabwe'
}

export const UNITED_KINGDOM = 'GB'
export const NON_GDPR_MARKETING_CONSENT_COUNTRIES = ['CA']

const COUNTRIES_WITHOUT_ZIP = [
  'AE',
  'AG',
  'AN',
  'AO',
  'AW',
  'BF',
  'BI',
  'BJ',
  'BO',
  'BS',
  'BW',
  'BZ',
  'CD',
  'CW',
  'DM',
  'ER',
  'GA',
  'GD',
  'GM',
  'GQ',
  'GY',
  'HK',
  'JM',
  'KN',
  'LC',
  'ML',
  'MO',
  'MS',
  'PA',
  'QA',
  'RW',
  'TD',
  'TL',
  'TT',
  'UG',
]

function _getGlobalCountryInputs(countryCode: string) {
  if (COUNTRIES_WITHOUT_ZIP.includes(countryCode)) {
    return omit(COUNTRY_INPUTS.GLOBAL, ['zipCode'])
  } else {
    return COUNTRY_INPUTS.GLOBAL
  }
}

for (const countryCode in GLOBAL_COUNTRIES) {
  const countryName = GLOBAL_COUNTRIES[countryCode]

  const inputs = _getGlobalCountryInputs(countryCode)

  COUNTRIES[countryName.toUpperCase()] = {
    id: countryName.toUpperCase(),
    name: countryName,
    code: countryCode,
    region: REGIONS.GLOBAL,
    inputs,
  }
}

const countryList = Object.keys(COUNTRIES).map((key) => COUNTRIES[key])
export const COUNTRY_CODES = countryList.map((c) => c.code)
export const getDefaultCountryId = () => {
  const country = countryList.find((c) => c.code === window.KEYS.ipCountry)
  return country ? country.id : COUNTRIES.UNITED_STATES.id
}

export const EURO_COUNTRIES = [
  'DE',
  'AT',
  'IE',
  'NL',
  'FI',
  'ES',
  'FR',
  'BE',
  'IT',
]
