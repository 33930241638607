import React, { useEffect, useState } from 'react'
import { promoCodeManager } from '../../../services/PromoCodeManager'
import { translateManager as t } from '../../../services/TranslateManager'
import './GiftCardSuccessDialog.scss'
import * as S from './GiftCardSuccessDialog.styles'
import Button, { SIZES } from 'mixtiles-web-common/ui/Button'
import { Drawer } from '@mui/material'
import MixtilesDialog from '../../MixtilesDialog/MixtilesDialog'
import { analytics } from '../../../services/Analytics/Analytics'
import useIllustration from 'mixtiles-web-common/hooks/useIllustration'
import useScreenSize from '../../../hooks/useScreenSize'

function PromoCodeDrawerContainer({ children, open, onDismiss, keepMounted }) {
  return (
    <Drawer
      classes={{
        paper: 'promo-code-success-drawer-container',
        root: 'promo-code-success-drawer-root',
      }}
      open={open}
      onClose={onDismiss}
      anchor="bottom"
      transitionDuration={{ enter: 350, exit: 250 }}
      keepMounted={keepMounted}
    >
      {children}
    </Drawer>
  )
}

export default function GiftCardSuccessDialog({
  open,
  title,
  subtitle,
  onClose,
  onDismiss,
  monitorTiming = undefined,
}) {
  const [openPromoCodeContainer, setOpenPromoCodeContainer] = useState(false)
  const [didOpenBefore, setDidOpenBefore] = useState(false)
  const isLargeScreen = useScreenSize('sm')
  const { illustration, isIllustrationLoaded } = useIllustration(
    S.DiscountIllustration
  )

  useEffect(() => {
    if (open) {
      /* If the outside switches to "open", set the internal openPromoCodeContainer value
       * to open after a tiny bit of delay so the dialog animates in smoothly.
       * If otherwise the component was just added to the component hierarchy and the state is "open" right away, the
       * dialog will just appear without any transition. */
      setTimeout(() => {
        setOpenPromoCodeContainer(true)
      }, 200)
    } else {
      if (openPromoCodeContainer) {
        setDidOpenBefore(true)
        const analyticsEventProperties = monitorTiming
          ? promoCodeManager.getTimeMonitorEvents()
          : {}
        analytics.track('Promo Code Popup Shown', analyticsEventProperties)
      }
      setOpenPromoCodeContainer(false)
    }
  }, [open])

  useEffect(() => {
    if (!didOpenBefore && openPromoCodeContainer) {
      promoCodeManager.monitorEventTime('Popup Shown')
    }
  }, [openPromoCodeContainer, didOpenBefore])

  const PromoCodeContainer = isLargeScreen
    ? MixtilesDialog
    : PromoCodeDrawerContainer

  return (
    <PromoCodeContainer
      open={openPromoCodeContainer && isIllustrationLoaded}
      onDismiss={onDismiss}
      fullScreen={false}
      keepMounted
    >
      <S.Container>
        {illustration}
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
        <S.ButtonContainer>
          <Button
            minWidth="185px"
            size={SIZES.xl}
            onClick={onClose}
            testId="promo-code-success-button"
          >
            {t.get('general.promo_code_success_dialog.call_to_action')}
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </PromoCodeContainer>
  )
}
